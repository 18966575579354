exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-blog-articles-1-next-gen-workforce-with-ai-cn-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/blog/articles/1-next-gen-workforce-with-ai-CN.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-blog-articles-1-next-gen-workforce-with-ai-cn-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-dip-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/readme/project/dip/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-dip-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-ee-4717-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/readme/project/EE4717/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-ee-4717-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-fyp-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/readme/project/fyp/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-fyp-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-index-js": () => import("./../../../src/pages/project/index.js" /* webpackChunkName: "component---src-pages-project-index-js" */),
  "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-blog-articles-1-next-gen-workforce-with-ai-cn-mdx": () => import("./../../../src/pages/project/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/blog/articles/1-next-gen-workforce-with-ai-CN.mdx" /* webpackChunkName: "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-blog-articles-1-next-gen-workforce-with-ai-cn-mdx" */),
  "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-dip-index-mdx": () => import("./../../../src/pages/project/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/readme/project/dip/index.mdx" /* webpackChunkName: "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-dip-index-mdx" */),
  "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-ee-4717-index-mdx": () => import("./../../../src/pages/project/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/readme/project/EE4717/index.mdx" /* webpackChunkName: "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-ee-4717-index-mdx" */),
  "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-fyp-index-mdx": () => import("./../../../src/pages/project/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/readme/project/fyp/index.mdx" /* webpackChunkName: "component---src-pages-project-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-readme-project-fyp-index-mdx" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

